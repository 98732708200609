.securityGuyHeader-cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 32px;
    align-items: center;

    &__search {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .securityGuyHeader-cont {
        gap: 16px;
        flex-wrap: wrap;

        &__btn {
            width: 100%;
        }
    }
}
.counter-cont {
    display: flex;
    width: 100%;
    max-width: 147px;

    &__btn1 {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    &__btn2{
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    &__countWrapper {
        width: 100%;
        max-width: 80px;
        display: flex;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }
}
.singleProduct-cont {
    height: calc(100% - 80px); // 100% - header-height
    width: 100%;
    padding: 32px 120px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    min-height: 500px;

    &__detailsWrapper {
        width: 100%;
        display: flex;
        gap: 32px;

        &__images {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 16px;
            max-width: 569px;

            .ant-image {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &__bigSlider {
                width: 100%;

                &__swiper {
                    display: flex;
                }
            }

        }

    }

    &__additionalInfo {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        max-width: 584px;

        &__middleText {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px
        }

        &__title {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
        }

        &__rateText {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        &__smallText {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px
        }

        &__boldTitle {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }

        &__reviewWrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 16px;

            &__addComment {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 8px;
                align-items: flex-end;

                &__textArea {
                    width: 100%;
                }

                &__divider {
                    margin: 0;
                    width: 100%;
                }
            }

            &__commentCont {
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 100%;

                &__rateCont {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    gap: 8px;

                    &__rate {
                        display: flex;
                        gap: 8px;
                        align-items: center;

                    }

                    &__likeCont {
                        display: flex;
                        gap: 24px;
                        align-items: center;

                        &__iconWrapper {
                            display: flex;
                            gap: 4px;
                            align-items: center;
                        }
                    }
                }

            }
        }
    }
}

@media screen and (max-width: 1240px) {
    .singleProduct-cont {
        padding: 32px 80px;

        &__detailsWrapper {
            &__images {
                max-width: 430px;
            }

        }

        &__additionalInfo {
            max-width: 430px;
        }
    }
}

@media screen and (max-width: 1080px) {
    .singleProduct-cont {
        padding: 32px 40px;
    }
}

@media screen and (max-width: 900px) {
    .singleProduct-cont {
        padding: 16px;
        height: calc(100% - 56px); //100% - header height
        gap: 16px;

        &__detailsWrapper {
            flex-direction: column;
            gap: 16px;

            &__images {
                max-width: 900px;
            }

        }

        &__additionalInfo {
            max-width: 900px;
        }
    }
}
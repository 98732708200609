.orderDetails-cont {
    width: 100%;
    display: flex;
    // flex: 1.6545 1 0;
    width: 67%;

    &__table {
        width: 100%;

        &__btnWrapper {
            width: 100%;
            max-width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@media screen and (max-width: 900px) {
    .orderDetails-cont {
        flex-direction: column;
        gap: 8px;
        width: 100%;

        &__card {
            width: 100%;

            &__descriptionCont {
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 100%;

                &__productCont {
                    display: flex;
                    flex-direction: column;
                    grid-area: 6px;

                    &__text {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                    }

                    &__textWrapper {
                        display: flex;
                        gap: 4px;
                        justify-content: space-between;
                        align-items: center;
                    }
                }
            }
        }


        .ant-card-body::after {
            display: none;
        }

        .ant-card-body::before {
            display: none;
        }
    }
}
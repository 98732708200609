.App-cont {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    .swiper-button-next,
    .swiper-button-prev {
        color: #8a9198;
    }

    .swiper-button-next.swiper-button-disabled {
        display: none;
    }

    .swiper-button-prev.swiper-button-disabled {
        display: none;
    }
}
.productCard {
    width: 100%;
    max-width: 250px;
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;

    &__coverWrapper {
        width: 100%;
        height: 100%;
        position: relative;

        &__btn {
            position: absolute;
            top: 8px;
            right: 8px;
        }

        .ant-image {
            width: 100%;
            height: 170px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__body {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 6px;

        &__text {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px
        }

        &__bigText {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px
        }

        &__rateWrapper {
            width: 100%;
            display: flex;
            gap: 4px;
            align-items: center;
            justify-content: space-between;
        }

        &__variantWrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 6px;
        }
    }
}

@media screen and (max-width: 900px) {
    .productCard {
        max-width: 350px;

        &__body {
            &__text {
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px
            }

            &__bigText {
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px;
            }

            &__rateWrapper {
                width: 100%;
                display: flex;
                gap: 4px;
                align-items: center;
            }
        }
    }
}
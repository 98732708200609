.login-cont {
    &__globalFilters {
        width: 100%;
        display: flex;
        gap: 32px;

        &__productsCountWrapper {
            width: 22%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;

            &__text {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
            }

            &__textsWrapper {
                display: flex;
                gap: 8px;
                align-items: center;
            }
        }

        &__filtersWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 78%;
            gap: 8px;

            &__searchInp {
                width: 100%;
                max-width: 360px;
            }

            &__selectWrapper {
                width: 100%;
                max-width: 280px;
                display: flex;
                gap: 8px;
                align-items: center;

                &__select {
                    width: 100%;
                    max-width: 210px;
                }
            }
        }
    }

    display: flex;
    justify-content: center;
    align-content: center;

    &-form {
        margin-top: 12px;
        align-content: center;

        max-width: 700px;

        input {
            width: 300px;
            height: 40px;
        }

        .login-form {
            max-width: 300px;
        }

        .login-form-forgot {
            float: right;
        }

        .login-form-button {
            width: 100%;
        }
    }
}

@media screen and (max-width: 600px) {
    .login-cont {
        &-form {
            width: 100%;
            max-width: 300px;
        }
    }
}
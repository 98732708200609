.cashFilters-cont {
    width: 100%;

    &__form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__formItem {
            width: 100%;
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 8px;
        }
    }
}

.singleProductHeader-cont {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 32px;

    &__titleWrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;

        &__title {
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 40px;
        }
    }

    &__detailWrapper {
        display: flex;
        align-items: center;
        gap: 16px;

        &__text {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
        }

        &__divider {
            margin: 0;
            height: 15px;
        }
    }
}

@media screen and (max-width: 900px) {
    .singleProductHeader-cont {
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;

        &__titleWrapper {
            &__title {
                font-size: 24px;
                line-height: 36px;
            }
        }

        &__detailWrapper {
            gap: 6px;
        }
    }
}
.cartForm-cont {
    width: 100%;
    // max-width: 728px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1.6545 1 0;

    &__formItemWrapper {
        width: 100%;
        display: flex;
        gap: 16px;
        align-items: flex-start;

        &__formItem {
            width: 100%;
            // max-width: 356px;
            margin-bottom: 0;

            &__text {
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px;
            }
        }

        &__formItemLong {
            width: 100%;
            margin: 0;
        }

        &__cont {
            width: 100%;
            display: flex;
            gap: 16px;
            justify-content: space-between;
            // flex: 1 0 0;
        }
    }

    &__btnWrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 8px;
    }

    .ant-form-item-required.ant-form-item-no-colon::before {
        content: "" !important;
    }
}

@media screen and (max-width: 900px) {
    .cartForm-cont {
        &__formItemWrapper {
            flex-direction: column;
            align-items: flex-start;
        }

    }
}
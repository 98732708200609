.checkout-cont {
    height: calc(100% - 80px); // 100% - header-height
    width: 100%;
    padding: 32px 120px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    min-height: 500px;

    &__header {
        width: 100%;
        display: flex;
        gap: 8px;
        align-items: center;

        &__title {
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 40px;
        }
    }

    &__bilDeatils {
        width: 100%;
        display: flex;
        gap: 32px;
    }

    &__prodDetails {
        width: 100%;
        display: flex;
        gap: 24px;
    }
}

@media screen and (max-width: 1240px) {
    .checkout-cont {
        padding: 32px 80px;
    }
}

@media screen and (max-width: 1080px) {
    .checkout-cont {
        padding: 32px 40px;
    }
}

@media screen and (max-width: 900px) {
    .checkout-cont {
        padding: 16px;
        height: calc(100% - 56px); //100% - header height
        gap: 16px;

        &__header {
            &__title {
                font-size: 24px;
                line-height: 36px;
            }
        }

        &__bilDeatils {
            flex-direction: column;
            gap: 16px;
        }

        &__prodDetails {
            flex-direction: column;
            gap: 16px;
        }
    }
}
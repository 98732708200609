.favorites-cont {
    height: calc(100% - 80px); // 100% - header-height
    width: 100%;
    padding: 32px 120px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: space-between;
    min-height: 500px;

    &__products {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(177.8px, 1fr));
        width: 100%;
        column-gap: 8px;
        row-gap: 8px;
        max-width: 100%;
        align-items: start; // Ensure items align at the start
        align-content: start; // Ensure content aligns at the start
        height: auto; // Ensure the container height fits its content
        min-height: auto;

        &__spinWrapper {
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    &__pagination {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}

@media screen and (max-width: 1240px) {
    .favorites-cont {
        padding: 32px 80px;
    }
}

@media screen and (max-width: 1080px) {
    .favorites-cont {
        padding: 32px 40px;
    }
}

@media screen and (max-width: 900px) {
    .favorites-cont {
        padding: 16px;
        height: calc(100% - 56px); //100% - header height
        gap: 16px;

        &__products {
            grid-template-columns: repeat(auto-fit, minmax(168px, 1fr));
            column-gap: 6px;
            row-gap: 6px;
        }
    }
}
.relatedProducts-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__title {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
    }
}

@media screen and (max-width: 900px) {
    .relatedProducts-cont {
        gap: 12px;

        &__title {
            font-size: 16px;
            line-height: 24px;
        }
    }
}
.createOrder-cont {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    &__text {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
}

@media screen and (max-width: 767px) {
    .createOrder-cont {
        width: 100%;
        gap: 12px;
    }
}
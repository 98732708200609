.securityGuy-cont {
    width: 100%;
    padding: 32px 120px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: calc(100% - 80px); // 100% - header-height
    min-height: 500px;

    &__pagination {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}

@media screen and (max-width: 1240px) {
    .securityGuy-cont {
        padding: 32px 80px;
    }
}

@media screen and (max-width: 1080px) {
    .securityGuy-cont {
        padding: 32px 40px;
    }
}

@media screen and (max-width: 900px) {
    .securityGuy-cont {
        padding: 16px;
        height: calc(100% - 56px); //100% - header height
        gap: 16px;
    }
}
.promoCodesSlider-cont {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__title {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px
    }

    &__sliderCont {
        width: 100%;
        display: flex;
        gap: 16px;
    }
}

@media screen and (max-width: 900px) {
    .promoCodesSlider-cont {
        &__sliderCont {
            gap: 12px;
        }
    }

}
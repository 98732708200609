.productSIze-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;

    &__secondaryTitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    &__titleCont {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;

        &__bigTitle {
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
        }

    }

    &__slidersCont {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__slidercont {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 8px;

            &__slide {
                width: 100%;
                height: 8px;
                border-radius: 4px;
                align-self: stretch;
                position: relative;

                &__active {
                    position: absolute;
                    top: 0;
                    height: 8px;
                    left: 0;
                    height: 8px;
                    border-radius: 4px;
                }
            }
        }
    }

    &__weightCont {
        width: 100%;
        display: flex;
        gap: 8px;
        flex-direction: column;

        &__btnWrapper {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;
        }
    }

    &__countCont {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}

@media screen and (max-width: 900px) {
    .productSIze-cont {
        gap: 18px;

        &__titleCont {
            &__bigTitle {
                font-size: 24px;
                line-height: 36px;
            }
        }

        &__slidersCont {
            &__slidercont {
                gap: 6px;
            }
        }

        &__weightCont {
            &__btnWrapper {
                gap: 6px;
            }
        }

    }
}
.appFooter-cont {
    display: flex;
    padding: 40px 120px;
    justify-content: space-between;
    gap: 32px;
    align-items: flex-start;

    &__socialMedia {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;

        &__titleWrapper {
            &__title {
                font-size: 50px;
                font-style: normal;
                font-weight: 600;
                line-height: 50px;
            }
        }

        &__iconsWrapper {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        &__textWrapper {
            display: flex;
            width: 100%;
            gap: 8px;
            align-items: center;

            &__text {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }
        }

        &__warningWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;

            &__text {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }
        }
    }

    // &__companyDetails {
    //     display: flex;
    //     gap: 12px;
    //     align-self: stretch;
    //     width: 100%;
    //     max-width: 492px;
    // }
}

@media screen and (max-width: 1240px) {
    .appFooter-cont {
        padding: 40px 80px;
    }
}

@media screen and (max-width: 1080px) {
    .appFooter-cont {
        padding: 40px 40px;
    }
}

@media screen and (max-width: 900px) {
    .appFooter-cont {
        padding: 16px 16px 32px 16px;
        gap: 32px;
        flex-direction: column;

        &__socialMedia {
            &__titleWrapper {
                &__title {
                    font-size: 32px;
                }
            }
        }

        // &__companyDetails {
        //     flex-direction: column;
        //     gap: 24px;
        //     width: 100%;
        //     max-width: 900px;
        // }
    }
}
.profile-cont {
    height: calc(100% - 80px); // 100% - header-height
    width: 100%;
    padding: 32px 120px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    min-height: 500px;

    &__form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 900px;
        flex-grow: 1;

        &__formItemWrapper {
            width: 100%;
            display: flex;
            gap: 24px;
            align-items: flex-start;

            &__formItem {
                width: 100%;
            }
        }

        &__formItemWrapperUpload {
            width: 100%;

            &__formItem {
                width: 100%;

                &__upload {
                    &__btn {
                        height: 40px !important;
                        width: 100% !important;
                    }


                }
            }

        }

        &__btnWrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
            max-width: 400px;
            margin-left: auto;
            margin-top: auto;
        }
    }

    &__formBig {
        width: 100%;
        max-width: none;
    }

    .ant-form-item {
        margin: 0px;
    }

    .ant-upload-wrapper {
        display: block;
        width: 100% !important;
        color: inherit;

        .ant-upload-select {
            width: 100%;
        }
    }

    .ant-upload-list-item-name {
        color: inherit;
    }
}

@media screen and (max-width: 1240px) {
    .profile-cont {
        padding: 32px 80px;
    }
}

@media screen and (max-width: 1080px) {
    .profile-cont {
        padding: 32px 40px;
    }
}

@media screen and (max-width: 900px) {
    .profile-cont {
        padding: 16px;
        height: calc(100% - 56px); //100% - header height
        gap: 16px;

        &__form {
            gap: 8px;

            &__formItemWrapper {
                gap: 8px;
                flex-direction: column;
            }

            &__btnWrapper {
                gap: 8px;
            }
        }
    }
}
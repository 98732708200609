.queDrawer-cont {
    &__title {
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 47px;
    }

    &__extraWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__contentWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}

@media screen and (max-width: 900px) {
    .queDrawer-cont {
        &__title {
            font-size: 24px;
            line-height: 30px;
        }
    }
}
.productDrawer-cont {
    &__title {
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 47px;
    }

    &__card {
        width: 100%;

        &__descriptionCont {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;

            &__text {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px
            }

            &__weightCont {
                width: 100%;
                display: flex;
                gap: 8px;
                flex-direction: column;

                &__btnWrapper {
                    display: flex;
                    gap: 8px;
                    flex-wrap: wrap;
                }
            }

            &__countCont {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }

        }
    }

}

@media screen and (max-width: 900px) {
    .productDrawer-cont {
        &__title {
            font-size: 24px;
            line-height: 30px;
        }

        &__card {
            &__descriptionCont {
                gap: 6px;

                &__text {
                    font-size: 14px;
                    line-height: 18px;
                }

                &__weightCont {
                    gap: 6px;

                    &__btnWrapper {
                        gap: 8px;
                    }
                }

                &__countCont {
                    gap: 6px;
                }

            }
        }

    }
}
.products-cont {
    width: 100%;
    padding: 32px 120px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: calc(100% - 80px); // 100% - header-height
    min-height: 500px;

    &__globalFilters {
        width: 100%;
        display: flex;
        gap: 32px;

        &__productsCountWrapper {
            width: 22%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;

            &__text {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
            }

            &__textsWrapper {
                display: flex;
                gap: 8px;
                align-items: center;
            }
        }

        &__filtersWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 78%;
            gap: 8px;

            &__searchInp {
                width: 100%;
                max-width: 360px;
            }

            &__selectWrapper {
                width: 100%;
                max-width: 280px;
                display: flex;
                gap: 8px;
                align-items: center;

                &__select {
                    width: 100%;
                    max-width: 210px;
                }
            }
        }
    }

    &__products {
        width: 100%;
        display: flex;
        gap: 32px;

        &__cartsCont {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(179.3px, 1fr));
            width: 78%;
            column-gap: 8px;
            row-gap: 8px;
            align-items: start; // Ensure items align at the start
            align-content: start; // Ensure content aligns at the start
            height: auto; // Ensure the container height fits its content
            min-height: auto;
        }
    }

    &__pagination {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: auto;
    }

    &__spinWrapper {
        align-items: center;
        align-content: center;
    }
}

@media screen and (max-width: 1240px) {
    .products-cont {
        padding: 32px 80px;
    }
}

@media screen and (max-width: 1080px) {
    .products-cont {
        padding: 32px 40px;

        &__globalFilters {
            &__productsCountWrapper {
                min-width: 230px;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .products-cont {
        padding: 16px;
        height: calc(100% - 56px); //100% - header height
        gap: 16px;

        &__globalFilters {
            flex-direction: column;
            gap: 16px;

            &__productsCountWrapper {
                width: 100%;
                max-width: 900px;
                align-items: flex-start;
                min-width: 200px;

                &__countCont {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    &__dropDown {
                        width: 100%;
                        max-width: 100px;

                        &__content {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;

                            &__text {
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 20px;
                            }
                        }
                    }
                }

                &__text {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px
                }

                &__textsWrapper {
                    width: 100%;
                }
            }

            &__filtersWrapper {
                width: 100%;
                max-width: 900px;

                &__searchInp {
                    width: 100%;
                    max-width: 900px;
                }
            }
        }

        &__products {
            gap: 16px;
            flex-direction: column;

            &__titleWrapper {
                width: 100%;

                &__title {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px
                }
            }

            &__cartsCont {
                width: 100%;
                max-width: 900px;
                grid-template-columns: repeat(auto-fit, minmax(168px, 1fr));
                column-gap: 6px;
                /* 6px gap between columns */
                row-gap: 6px;
            }
        }
    }
}
.cashierCheckout-cont {
    width: 100%;
    // max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 8px;
    // flex: 1 1 0;
    align-items: flex-start;

    // height: fit-content;
    &__promoCodeWrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;

        &__promoInp {
            .ant-select-selector {
                background-color: transparent !important;
            }
        }
    }

    &__divider {
        margin: 0;
    }

    &__textWrapper {
        display: flex;
        gap: 24px;
        justify-content: space-between;
        align-items: center;
    }

    &__patientCont {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 8px;
        align-items: flex-start;
    }

    &__text {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    &__title {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
    }

    .ant-card-body::after {
        display: none;
    }

    .ant-card-body::before {
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .cashierCheckout-cont {
        width: 100%;
        gap: 12px;

        &__textWrapper {
            gap: 12px;
        }

        .ant-card-body::after {
            display: none;
        }

        .ant-card-body::before {
            display: none;
        }
    }
}
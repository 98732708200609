.promoCard-cont {
    width: 100%;
    border-radius: 6px;
    border: none;
    border-left: 3px solid;

    &__bodyCont {
        display: flex;
        flex-direction: column;
        gap: 2px;

        &__textWrapper {
            width: 100%;
            display: flex;
            gap: 2px;
            align-items: center;

            &__text {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }
        }

        &__divider {
            margin: 0;
        }

    }
}

@media screen and (max-width: 900px) {
    .promoCard-cont {
        &__bodyCont {
            &__textWrapper {
                &__text {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px
                }
            }

        }
    }
}
.productFilters-cont {
    width: 21%;

    &__form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;

        &__formItem {
            width: 100%;
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 8px;
        }
    }
}

@media screen and (max-width: 1080px) {
    .productFilters-cont {
        min-width: 235px;
    }
}

@media screen and (max-width: 900px) {
    .productFilters-cont {
        width: 100%;
        max-width: 900px;
        min-width: none;

        &__form {
            gap: 16px;
        }
    }
}
.signUp-cont {
    border-radius: 8px;
    width: 100%;
    max-width: 520px;

    &__form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;

        &__formItemWrapper {
            width: 100%;
            display: flex;
            gap: 12px;
            align-items: flex-start;

            &__formItem {
                width: 100%;

                &__text {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                }
            }
        }
    }

    &__cancelBtn {
        display: none;
    }

    &__btn {
        width: 100%;
        // max-width: 80px;
        margin: 0 !important;
    }

    .ant-form-item-required.ant-form-item-no-colon::before {
        content: "" !important;
    }

    .ant-form-item {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 600px) {
    .signUp-cont {
        max-width: 300px;

        &__form {
            gap: 4px;

            &__formItemWrapper {
                flex-direction: column;
                gap: 8px;
                justify-content: center;
            }
        }
    }
}
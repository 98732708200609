.resetPassword-cont {
    height: calc(100% - 80px); // 100% - header-height
    width: 100%;
    padding: 32px 120px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    justify-content: center;
    min-height: 500px;

    &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        max-width: 300px;

        &__title {
            font-size: 26px;
            font-weight: 500;
            line-height: 40px;
            align-self: center;
        }

        &__form {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 24px;

            &__formItem {
                width: 100%;
            }
        }
    }

    .ant-form-item {
        margin: 0px;
    }
}

@media screen and (max-width: 1240px) {
    .resetPassword-cont {
        padding: 32px 80px;
    }
}

@media screen and (max-width: 1080px) {
    .resetPassword-cont {
        padding: 32px 40px;
    }
}

@media screen and (max-width: 900px) {
    .resetPassword-cont {
        padding: 16px;
        height: calc(100% - 56px); //100% - header height
        gap: 16px;

        &__wrapper {
            gap: 16px;

            &__form {
                gap: 16px;

            }
        }
    }
}
.cashier-cont {
    width: 100%;
    padding: 32px 120px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: calc(100% - 80px); // 100% - header-height
    min-height: 500px;

    &__globalFilters {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 16px;

        &__productsCountWrapper {
            width: 100%;
            display: flex;
            align-items: flex-start;
            gap: 8px;
            min-width: 200px;

            &__countCont {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 8px;

                &__dropDown {
                    width: 100%;
                    max-width: 100px;

                    &__content {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;

                        &__text {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                        }
                    }
                }
            }

            &__textsWrapper {
                display: flex;
                gap: 8px;
                align-items: center;
            }

            &__text {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
            }
        }
    }

    &__products {
        width: 100%;
        display: flex;
        gap: 32px;

        &__cartsCont {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(179.3px, 1fr));
            width: 70%;
            column-gap: 8px;
            row-gap: 8px;
            align-items: start; // Ensure items align at the start
            align-content: start; // Ensure content aligns at the start
            height: auto; // Ensure the container height fits its content
            min-height: auto;
        }

    }

    &__pagination {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: auto;
    }

    &__spinWrapper {
        align-items: center;
        align-content: center;
    }
}

@media screen and (max-width: 1240px) {
    .cashier-cont {
        padding: 32px 80px;
    }
}

@media screen and (max-width: 1080px) {
    .cashier-cont {
        padding: 32px 40px;
    }
}

@media screen and (max-width: 900px) {
    .cashier-cont {
        padding: 16px;
        height: calc(100% - 56px); //100% - header height
        gap: 16px;

        &__products {
            gap: 16px;

            @media screen and (max-width: 767px) {
                flex-direction: column;

                &__titleWrapper {
                    width: 100%;

                    &__title {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px
                    }
                }

                &__cartsCont {
                    width: 100%;
                    max-width: 900px;
                    grid-template-columns: repeat(auto-fit, minmax(168px, 1fr));
                    column-gap: 6px;
                    /* 6px gap between columns */
                    row-gap: 6px;
                }
            }
        }
    }
}
.appHeader-cont {
    width: 100%;
    padding: 0px 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80px;

    &__flex {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 16px;

        &__titleWrapper {
            width: 100%;
            display: flex;
            align-items: center;

            &__title {
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: 50px;
            }
        }

        &__filters {
            display: flex;
            gap: 16px;
            width: 100%;
            justify-content: flex-end;


            &__navigationBar {
                display: flex;
                gap: 8px;
                align-items: center;

                &__btnsWrapper {
                    display: flex;
                    gap: 4px;
                    align-items: center;
                }

                &__authWrapper {
                    display: flex;
                    gap: 8px;
                }
            }
        }
    }
}

@media screen and (max-width: 1240px) {
    .appHeader-cont {
        padding: 0px 80px;
    }
}

@media screen and (max-width: 1080px) {
    .appHeader-cont {
        padding: 0px 40px;
    }
}

@media screen and (max-width: 900px) {
    .appHeader-cont {
        padding: 8px;
        height: 56px;

        &__flex {
            gap: 8px;

            &__titleWrapper {
                width: 100%;
                max-width: 900px;
                display: flex;
                gap: 8px;
                align-items: center;

                &__title {
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 30px
                }
            }
        }
    }
}
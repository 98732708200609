.notFountPage-cont {
    width: 100%;
    padding: 32px 120px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: calc(100% - 80px); // 100% - header-height
    min-height: 500px;

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        margin-top: auto;
        margin-bottom: auto;

        &__text {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 36px;
        }

        &__title {
            font-size: 72px;
            font-style: normal;
            font-weight: 600;
            line-height: 64px;
        }
    }
}

@media screen and (max-width: 1240px) {
    .notFountPage-cont {
        padding: 32px 80px;
    }
}

@media screen and (max-width: 1080px) {
    .notFountPage-cont {
        padding: 32px 40px;
    }
}

@media screen and (max-width: 900px) {
    .notFountPage-cont {
        padding: 16px;
        height: calc(100% - 56px); //100% - header height
        gap: 16px;
    }
}
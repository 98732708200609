.profileHeader-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;

    &__header {
        width: 100%;
        display: flex;
        gap: 8px;
        align-items: center;

        &__title {
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 40px;
        }
    }

    &__badge-indicator {
        width: 12px !important;
        height: 12px !important;
    }

}

@media screen and (max-width: 900px) {
    .profileHeader-cont {
        gap: 16px;

        &__header {
            &__title {
                font-size: 24px;
                line-height: 36px;
            }
        }

    }
}